import React from "react";
import { motion } from "framer-motion";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import projects from "../../../data/projectData";

const fadeInAnimationVariants = {
  initial: {
    opacity: 0,
    y: 10,
  },
  animate: (index) => ({
    opacity: 5,
    x: 0,
    transition: {
      delay: 0.3 * index,
    },
  }),
  boxhover: {
    scale: 1.1,
  },
  hover: {
    scale: 1.1,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  },
};

const Project = () => {
  return (
    <div className="bg-white">
      <Navbar />
      <div className="container mx-auto pt-24 pb-10">
        <div className="box justify-center">
          {projects.map((project, index) => (
            <motion.div
              key={index}
              custom={index}
              variants={fadeInAnimationVariants}
              initial="initial"
              whileInView="animate"
              whileHover="boxhover"
              viewport={{
                once: true,
              }}
              className="group mb-3 sm:mb-8 last:mb-0"
            >
              <section className="max-w-[48rem] m-auto border border-black/5 rounded-lg overflow-hidden sm:pr-8 relative sm:h-[25rem] hover:bg-gray-200 transition sm:group-even:pl-8 dark:text-white dark:bg-white/10 dark:hover:bg-white/20">
                <img
                  src={project.imageUrl}
                  alt="Project I worked on"
                  quality={95}
                  className="block sm:hidden"
                />
                <div className="pt-4 pb-7 px-5 sm:pl-10 sm:pr-2 sm:pt-10 sm:max-w-[55%] flex flex-col h-full sm:group-even:ml-[20rem]">
                  <div className="text-gray-800">
                    <h3 className="text-xl font-semibold">{project.title}</h3>
                    <p
                      className="mt-2 leading-relaxed text-sm"
                      dangerouslySetInnerHTML={{ __html: project.description }}
                    ></p>
                  </div>
                  <ul className="flex flex-wrap mt-4 gap-2 sm:mt-auto">
                    {project.tags.map((tag, index) => (
                      <motion.li
                        className="bg-none px-3 py-1 text-[0.8rem] text-black cursor-pointer font-medium rounded-full"
                        key={index}
                        variants={fadeInAnimationVariants}
                        whileHover="hover"
                        viewport={{
                          once: true,
                        }}
                        custom={index}
                        // style={{
                        //   background: `hsl(${index * 30}, 70%, 60%)`, // Generate dynamic background color
                        // }}
                      >
                        {tag}
                      </motion.li>
                    ))}
                  </ul>
                  <div className="flex justify-start gap-2 pt-10">
                    {project.link && (
                      <motion.div
                        className="flex rounded-full content-center text-xs bg-cyan-800 py-1 px-2 text-white hover:bg-sky-600 cursor-pointer"
                        variants={fadeInAnimationVariants}
                        whileHover="hover"
                        viewport={{
                          once: true,
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        <a className="content-center" href={project.link}>
                          {project.buttonType}
                        </a>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-4 ml-0 text-white transition"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                          />
                        </svg>
                      </motion.div>
                    )}
                    {project.route && (
                      <motion.div
                        className="flex border border-cyan-800 rounded-full text-xs py-1 px-2 text-cyan-900 hover:bg-sky-200 cursor-pointer"
                        variants={fadeInAnimationVariants}
                        whileHover="hover"
                        viewport={{
                          once: true,
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <a className="content-center" href={project.route}>
                          {project.buttonCta}
                        </a>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-4 ml-0 text-cyan-800 transition"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                          />
                        </svg>
                      </motion.div>
                    )}
                  </div>
                </div>

                <img
                  src={project.imageUrl}
                  alt="Project I worked on"
                  quality={95}
                  className="absolute hidden sm:block top-8 -right-40 w-[28.25rem] rounded-t-lg shadow-2xl
        transition 
        group-hover:scale-[1.04]
        group-hover:-translate-x-3
        group-hover:translate-y-3
        group-hover:-rotate-2

        group-even:group-hover:translate-x-3
        group-even:group-hover:translate-y-3
        group-even:group-hover:rotate-2

        group-even:right-[initial] group-even:-left-40"
                />
              </section>
            </motion.div>
          ))}
        </div>
      </div>
      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
};

export default Project;
