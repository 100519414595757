import React from "react";
import avatar from "../img/Picture.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="navbar fixed sm:gap-[50px] xl:gap-[130px] bg-white z-20 sm:justify-center">
      <div className="box">
        <div className="dropdown block sm:hidden">
          <div tabIndex={0} role="button" className="btn btn-ghost">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </div>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content mt-3 p-2 shadow bg-slate-100 text-gray-500 rounded-box w-52"
          >
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>
              <details>
                <summary>About</summary>
                <ul className="p-2 bg-slate-100 text-gray-500">
                  <li>
                    <Link to={"/aboutSection"}>About Me</Link>
                  </li>
                  <li>
                    <Link to={"/Skills"}>Skills</Link>
                  </li>
                  <li>
                    <Link to={"/Experience"}>Experience</Link>
                  </li>
                </ul>
              </details>
            </li>
            <li>
              <details>
                <summary>Works</summary>
                <ul className="p-2 bg-slate-100 text-gray-500">
                  <li>
                    <Link to={"/Project"}>Applications</Link>
                  </li>
                  <li>
                    <Link to={"/UserInterface"}>Web and UI/UX Designs</Link>
                  </li>
                  <li>
                    <Link to={"/Graphic"}>Graphic Design Portfolio</Link>
                  </li>
                </ul>
              </details>
            </li>
            <li>
              <Link to={"/Contact"}>Contact</Link>
            </li>
          </ul>
        </div>
        <img
          alt="Tailwind CSS Navbar component"
          src={avatar}
          className="block md:hidden w-10 rounded-full"
        />
        <p className="pl-3 sm:pl-0 text-base text-gray-500">
          <Link to={"/"}>Marven Paray</Link>
        </p>
      </div>
      <div className="navbar-center hidden sm:block lg:flex p-0">
        <ul className="menu menu-horizontal px-1 text-base text-gray-500">
          <li>
            <Link to={"/"}>Home</Link>
          </li>
          <li>
            <details>
              <summary>About</summary>
              <ul className="p-2 bg-slate-100 text-gray-500">
                <li>
                  <Link to={"/aboutSection"}>About Me</Link>
                </li>
                <li>
                  <Link to={"/Skills"}>Skills</Link>
                </li>
                <li>
                  <Link to={"/Experience"}>Experience</Link>
                </li>
              </ul>
            </details>
          </li>
          <li>
            <Link to={"/project"}>Works</Link>
            {/*<details>
              <summary>Works</summary>
              <ul className="p-2 w-72 bg-slate-100 text-gray-500">
                <li>
                  <Link to={"/Project"}>Web and Mobile Applications</Link>
                </li>
                <li>
                  <Link to={"/UserInterface "}>Web and UI/UX Designs</Link>
                </li>
                <li>
                  <Link to={"/Graphic"}>Graphic Design Portfolio</Link>
                </li>
              </ul>
            </details>*/}
          </li>
        </ul>
      </div>
      <div className="btn btn-ghost">
        <Link
          className="hidden sm:block text-gray-500 text-base"
          to={"/contact"}
        >
          Contact
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
