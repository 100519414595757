import React from "react";
import { Link } from "react-router-dom";
import LetterAnimation from "../../LetterAnimation";
import Footer from "../../Footer";
import Navbar from "../../Navbar";

const aboutSection = () => {
  const textToAnimate = "About Me! 🧑‍💼";
  return (
    <div>
      <Navbar />
      <div className="hero min-h-screen bg-white">
        <div className="hero-content max-w-[75ch] sm:pt-32">
          <div>
            <h1 className="text-3xl font-bold text-zinc-700 pb-4">
              <LetterAnimation text={textToAnimate} />
            </h1>
            <p className="mb-3 text-zinc-500 text-lg leading-relaxed text-justify">
              After graduating with a degree in{" "}
              <span className="font-medium">BS in Information System</span>, I
              decided to pursue my passion for coding and programming. I am a
              self taught{" "}
              <span className="font-medium">
                developer, and I have a 1 year and 3 months experience gain from
                my previous job as a Web Developer and also leverage my UI/UX
                design skills by designing a landing page, and adding ui
                features, API integrations, and before I was a Part-Time
                Instructor at Private College teaching about programming
                language, and other course subject related to Information
                technologies for 1 year.
              </span>
              <span className="italic"> My favorite part of programming</span>{" "}
              is the problem-solving and the design aspect. I{" "}
              <span className="underline">love</span> the feeling of finally
              figuring out a solution to a problem and designing a web and
              mobile apps. My core stack is{" "}
              <span className="font-medium">
                React js for javascript framework, Next.js for meta-frame works,
                Node.js for the server-side, MySQL for the dialect of the
                database, CSS Frameworks for the designs, NPM libraries for
                other integrations and API Integrations
              </span>
            </p>

            <p className="text-lg text-zinc-500 leading-relaxed pb-10 md:pb-5 text-justify">
              I am currently looking for a{" "}
              <span className="font-medium">
                Full-Time, Part-Time, and Freelance position
              </span>{" "}
              as a Front End developer or a Full Stack developer.
              <span className="italic"> When I'm not coding</span>, I enjoy
              playing video games, watching movies, and playing with my dog. I
              also enjoy{" "}
              <span className="font-medium">learning new things</span> creating
              side projects, and exploring new ideas. I am currently learning
              about <span className="font-medium">psychology and history</span>.
              I'm also learning how to play the guitar. <br /> <br /> I am
              always looking to learn new technologies.
            </p>
            <Link className="btn btn-outline text-zinc-600" to={"/contact"}>
              Lets's Connect
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <Footer />
      </div>
    </div>
  );
};

export default aboutSection;
