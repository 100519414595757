import clocknpay from "../img/clocknpay.png";
import Purpleroof from "../img/purpleroof.png";
import Learndev from "../img/learndev.png";
import Gabby from "../img/Coverchatbot.png";
import Posh from "../img/posh.png";
import Smart from "../img/smart.png";
import Pick from "../img/Pickadev.png";
import Ecommerce from "../img/ecommerce-v3.png";
import StarImage from "../img/star-world.png";

const projectsData = [
  {
    title: "STAR WORLD MEDIA GROUP",
    description:
      "STAR WORLD MEDIA GROUP is a digital media agency for media technology, they've took the design inspiration of the website https://axtra-next-agency.vercel.app/creative-agency-dark from Wealcoder, as a front-end developer i've clone the website inspiration from scratch.",
    tags: ["Next js", "Vercel", "internationalization", "Tailwind"],
    imageUrl: StarImage,
    link: "https://star-world-v2.vercel.app/en",
    route: undefined,
    buttonType: "See Demo App",
    buttonCta: "Learn more",
  },
  {
    title: "OptimalK9Wellness",
    description:
      "OptimalK9Wellness is a e-commerce for dog accessories etc., As a Web Developer and Designer, I am part of the collaboration in developing and creating a e-commerce website for OptimalK9Wellness.",
    tags: ["Wordpress", "Elementor", "Wocommerce", "Bluehost"],
    imageUrl: Ecommerce,
    // link: "https://optimalk9wellness.com/",
    link: undefined,
    route: undefined,
    buttonType: "See App",
    buttonCta: "Learn more",
  },
  {
    title: "Clocknpay",
    description:
      "In my recent professional endeavor, I had the privilege of collaborating closely with a dynamic team to spearhead the UI design for ClocknPay.",
    tags: ["Next js", "Tailwind", "Node js"],
    imageUrl: clocknpay,
    link: "https://clocknpay.netlify.app/",
    route: undefined,
    buttonType: "See Demo",
    buttonCta: "Learn more",
  },
  {
    title: "PurpleRoof",
    description:
      "In my previous job as a Front-End Developer, I undertook a dynamic project to collaborated and add certain features to the PurpleRoof app user interface.",
    tags: ["React", "Next.js", "Tailwind", "Typescript"],
    imageUrl: Purpleroof,
    link: undefined,
    route: undefined,
    buttonType: "Explore App",
    buttonCta: "Learn more",
  },
  {
    title: "Poshsidekick",
    description:
      "In my previous job as a Front-End Developer, I undertook a dynamic project to collaborated and add certain features to the Poshsidekick app user interface.",
    tags: [
      "React Native",
      "Typescript",
      "React CSS",
      "React native components",
    ],
    imageUrl: Posh,
    link: undefined,
    route: undefined,
    buttonType: "Learn More",
    buttonCta: "Learn more",
  },
  {
    title: "Pick.com",
    description:
      "This is a side project that i been developing, It will soon deploy",
    tags: [
      "Next js",
      "Tailwind",
      "Typescript",
      "MySQL",
      "Sequelize ORM",
      "Node js",
    ],
    imageUrl: Pick,
    link: "https://pickdotcom.vercel.app/",
    route: undefined,
    buttonType: "See Demo App",
    buttonCta: "Learn more",
  },
  {
    title: "Smartstudio",
    description:
      "In my previous job as a Front-End Developer, I undertook a dynamic project to collaborated, add and refactor certain features in the front-end to the Smartstudio app user interface.",
    tags: ["React js", "Next.js", "Tailwind", "Typescript"],
    imageUrl: Smart,
    link: "https://www.smartsite.studio/",
    route: undefined,
    buttonType: "See App",
    buttonCta: "Learn more",
  },
  {
    title: "LearnDev",
    description:
      "Embarking on the development of my Learn-Dev App from scratch, I aimed to create a tailored learning platform.",
    tags: ["React", "Next.js", "Youtube API", "Tailwind", "Daisy UI"],
    imageUrl: Learndev,
    link: "https://learn-dev-one.vercel.app/",
    route: undefined,
    buttonType: "See Demo",
    buttonCta: "Learn more",
  },
  {
    title: "Gabby",
    description:
      "In my freelance role, I undertook a dynamic project-based to develop a mobile application and design the user interface for a chatbot named Gabie. ",
    tags: ["React Native", "Firebase", "Dialogflow"],
    imageUrl: Gabby,
    link: "https://github.com/Nevram30/Gabie-chatbot",
    route: undefined,
    buttonType: "Explore Code",
    buttonCta: "Learn more",
  },
];
export default projectsData;
