const experiencesData = [
  {
    title: "IS Instructor (Part-Time)",
    location: "Apokon, Tagum City",
    description:
      "Spent 5 months as a Information System Instructor at STI College Tagum, Teaching IS subjects, and Programming",
    date: "2024 - 6 months",
    company: "STI Tagum",
  },
  {
    title: "Web Designer",
    location: "Davao City, Eco-land, Philippines",
    description: "Lead Designer for website design on ecommerce for 2 months",
    date: "2024 - 3 months",
    company: "K9maniadogtraining.com",
  },
  {
    title: "Front-end Developer",
    location: "Panabo City, Philippines",
    description:
      "My role as a Front-end developer is to add new features, improve the user interface, and integrate API's.",
    date: "2023 - 2024",
    company: "Blank Space",
  },
  {
    title: "IT Instructor (Part-Time)",
    location: "Lasang, Davao City, Philippines",
    description:
      "Taught computer programming, database management, and web development.",
    date: "2022 - 2023",
    company: "Gabriel Taborin College of Davao Foundation, Inc.",
  },
  {
    title: "UI/UX Designer (Intern)",
    location: "Cagayan de Oro City, Philippines (Remote)",
    description:
      "Before I graduated I apply as an intern at Wela School Systems as a UI/UX Designer in 6 months. I learned a lot from User Interface to User Experience.",
    date: "Jan - May 2022",
    company: "Wela School Systems",
  },
];

export default experiencesData;
