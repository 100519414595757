import React, { useState } from "react";
import Footer from "../Footer";
import LetterAnimation from "../LetterAnimation";
import { motion } from "framer-motion";
import Navbar from "../Navbar";

const CopyConfirmationMessage = ({ show }) => {
  return (
    <div
      style={{
        display: show ? "block" : "none",
        position: "fixed",
        bottom: 10,
        right: 10,
        padding: 10,
        background: "green",
        color: "white",
        borderRadius: 5,
      }}
    >
      Email copied!
    </div>
  );
};

const Contact = () => {
  const fadeInAnimationVariants = {
    initial: {
      opacity: 0,
      y: 10,
    },
    animate: (index) => ({
      opacity: 5,
      x: 0,
      transition: {
        delay: 0.3 * index,
      },
    }),
  };

  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const textToAnimate = "Let's work together. 🤝";

  const handleCopyEmail = () => {
    const emailElement = document.getElementById("email");
    const emailText = emailElement.innerText;

    navigator.clipboard.writeText(emailText);

    setShowCopyMessage(true);

    setTimeout(() => {
      setShowCopyMessage(false);
    }, 4000);
  };

  return (
    <div className="bg-white">
      <Navbar />
      <div className="container mx-auto">
        <div className="hero h-screen">
          <div className="hero-content flex-col max-w-[75ch] !items-start">
            <h1 className="text-3xl font-bold text-zinc-600">
              <LetterAnimation text={textToAnimate} />
            </h1>
            <motion.div
              variants={fadeInAnimationVariants}
              initial="initial"
              whileInView="animate"
              viewport={{
                once: true,
              }}
              className="group mb-3 sm:mb-8 last:mb-0"
            >
              <p className="py-6 text-zinc-900 text-lg text-justify">
                I am open to engaging in exciting freelance opportunities and
                projects.
                <br /> <br /> If you are interested in working with me, the most
                efficient way to reach me is by sending an email to
                <span className="pl-1 font-semibold">
                  <a
                    id="email"
                    href="mailto:mars.paray@gmail.com"
                    onClick={handleCopyEmail}
                    style={{ cursor: "pointer" }}
                  >
                    <span className="border-b-4 border-[#FA9E23] inline-block">
                      {" "}
                      mars.paray@gmail.com 📧
                    </span>
                  </a>
                </span>
                <br /> <br /> I look forward to hearing from you.
              </p>
            </motion.div>
          </div>
        </div>
      </div>
      <CopyConfirmationMessage
        show={showCopyMessage}
        onClose={() => setShowCopyMessage(false)}
      />
      <div className="fixed w-full z-50 bottom-0">
        <Footer />
      </div>
    </div>
  );
};

export default Contact;
