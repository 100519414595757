import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/HomeWrapper";
import AboutSection from "./components/Sections/Works/aboutSection";
import Project from "./components/Sections/Works/Project";
import Experience from "./components/Sections/Works/Experience";
import Skills from "./components/Sections/Skills";
import Contact from "./components/Sections/contact";
import UserInterface from "./components/Sections/Works/UserInterface";
import Graphic from "./components/Sections/Works/Graphic";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/graphic" element={<Graphic />} />
          <Route path="/UserInterface" element={<UserInterface />} />
          <Route path="/Project" element={<Project />} />
          <Route path="/Experience" element={<Experience />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Skills" element={<Skills />} />
          <Route path="/aboutSection" element={<AboutSection />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
