import React from "react";
import LetterAnimation from "../LetterAnimation";
import Footer from "../Footer";
import { motion } from "framer-motion";
import { Typewriter } from "react-simple-typewriter";

import avatar from "../../img/Picture.png";
import { Link } from "react-router-dom";
const fadeInAnimationVariants = {
  initial: {
    opacity: 0,
    y: 10,
  },
  animate: (index) => ({
    opacity: 8,
    x: 0,
    transition: {
      delay: 0.3 * index,
    },
  }),
};

const FadeLeftAnimation = {
  initial: {
    opacity: 0,
    x: "100vw",
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const HeroSection = () => {
  const textToAnimate = "Kumusta! 👋 I'm Marven Paray a";

  return (
    <>
      <div className="hero min-h-screen bg-white">
        <div className="hero-content max-w-[75ch]">
          <div>
            <div className="flex flex-row gap-8">
              <img
                alt="Tailwind CSS Navbar component"
                src={avatar}
                className="hidden md:block rounded-full w-[120px] h-[130px]"
              />
              <div className="content-end">
                <h1 className="text-3xl font-bold text-zinc-600 pt-5">
                  <LetterAnimation text={textToAnimate} />
                </h1>
                <p className="font-semibold pt-2 text-2xl sm:text-3xl text-zinc-600">
                  <Typewriter
                    words={["Full-Stack Developer", "Web Designer"]}
                    loop={0}
                    cursor
                    cursorStyle="_"
                    typeSpeed={70}
                    deleteSpeed={50}
                    delaySpeed={1000}
                  />
                </p>
              </div>
            </div>
            <motion.div
              variants={FadeLeftAnimation}
              initial="initial"
              animate="animate"
            ></motion.div>
            <motion.div
              variants={fadeInAnimationVariants}
              initial="initial"
              whileInView="animate"
              viewport={{
                once: true,
              }}
              className="group mb-3 sm:mb-8 last:mb-0 pt-3"
            >
              <p className=" text-zinc-900 text-md">
                I am an expert in building and designing websites and web
                applications that are user-friendly, accessible, and visually
                appealing. I have experience working with various technologies,
                including JavaScript, Typescript, Wordpress and CSS frameworks.{" "}
                <br />
                <br />
                you can check also my portfolio in Web and UI/UX design:
                <a
                  href="https://paray-design.vercel.app/"
                  className="border-b-2 border-orange-600 cursor-pointer"
                >
                  https://paray-design.vercel.app/
                </a>{" "}
                <br />
                <br />
                Interested in collaborating?{" "}
                <span className="border-b-2 border-orange-600">
                  <Link to="/contact">Let's connect.</Link>
                </span>
              </p>
            </motion.div>
            <button className="btn btn-outline mt-4 sm:mt-0">
              <a href="/CV.pdf" download>
                Download CV
              </a>
            </button>
          </div>
        </div>
      </div>
      <div className="fixed w-full z-50 bottom-0">
        <Footer />
      </div>
    </>
  );
};

export default HeroSection;
